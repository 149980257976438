<template>
    <div class="answer-box">
        <p class="message-answer">{{ answer }}</p>
    </div>
</template>

<script>
export default {
    props: {
        answer: String,
    }
}
</script>