<template>
  <div class="full">
    <ChatComponent></ChatComponent>
  </div>
</template>

<script>
import ChatComponent from '@/components/ChatComponent.vue'

export default {
  name: 'App',
  components: {
    ChatComponent,
  }
}
</script>

<style>
body {
  font-family: 'Poppins';
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

.full {
  background-color: #fe4040;
  width: 100vw;
  height: 100vh;
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
