<template>
    <div class="question-box">
        <p class="message">{{ question }}</p>
    </div>
</template>

<script>
export default {
    props: {
        question: String,
    }
}
</script>