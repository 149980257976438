<template>
    <div class="chat-box">
        <div class="chat-upper">
            <h1 class="title">core1.chat</h1>
        </div>
        <div class="chat-section">
            <div v-for="chat, index in chatData" :key="index">
                <chatQuestion v-if="chat.type === 'question'" :question="chat.text"/>
                <ChatAnswer v-if="chat.type === 'answer'" :answer="chat.text"/>
            </div>
        </div>
        <div class="question-prompt">
            <input type="text" id="textInput" class="text-input" placeholder="Napište něco..." v-model="question"/>
            <button class="send" @click="sendQuestion"><img src="send.svg" class="icon"></button>
        </div>
    </div>
</template>

<style>
    .chat-box {
        width: 20%;
        height: 70%;
        background-color: #f7f7f7;
        border-radius: 2.5rem;
        border: 10px solid #ff9899;
        overflow: hidden;
        padding: 5px;
    }

    .chat-upper {
        height: 10%;
        background-color: #fe4040;
        margin: 0px;
        margin: -5px;
        margin-top: -30px;
        margin-bottom: 10px;
    }

    .question-box {
        padding-left: 15px;
        padding-right: 15px;
        text-align: left;
        background-color: #fe4040;
        border-radius: 10px;
        display: flex;
        margin-left: 60px;
        margin-bottom: 10px;
        line-height: 1.3em;
        
    }

    .answer-box {
        text-align: left;
        padding-left: 15px;
        background-color: #eaeaea;
        border-radius: 10px;
        display: flex;
        margin-right: 60px;
        margin-bottom: 10px;
        padding-right: 15px;
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .chat-section {
        height: 82%;
        overflow: auto;
        padding-right: 5px;
        margin-bottom: 10px;
    }


    .title {
        color : #ffffff;
        padding-top: 20px;
    }

    .message {
        color : #ffffff;
    }

    .message-answer {
        color : #000000;
    }

    .text-input {
        border-radius: 2.5rem;
        border: 0;
        background-color: rgb(239, 239, 239);
        width: 70%;
        height: 100%;
        padding-left: 15px;
    }

    .text-input:focus{
        border-radius: 2.5rem;
        border: 0;
        background-color: rgb(239, 239, 239);
        width: 70%;
        height: 30px;
        outline: none;
        padding-left: 15px;
    }

    .send {

        font-size: 10px;
        width: 60px;
        color: #fff;
        background-color: #fe4040;
        height: 100%;
        border: none;
        box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
        border-radius: 33px;
        transition : 1000ms;
        transform: translateY(0);
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .send-icon {
        height: 20px;
        width: 20px;
    }


    ::-webkit-scrollbar-track {
        background-color: transparent; 
    }

    ::-webkit-scrollbar-thumb {
        background-color: #fe4040; 
        border-radius: 25px;
    }

    ::-webkit-scrollbar {
        width: 4px;
    }
    
    .question-prompt {
        height: 60px;
    }
</style>

<script>
    import ChatAnswer from './ChatAnswer.vue';
    import ChatQuestion from './ChatQuestion.vue';

    export default {
        components: {
            ChatAnswer,
            ChatQuestion,
        },
        data() {
            return {
                question: '',
                chatData: [],
            }
        },
        methods: {
            sendQuestion() {
                const apiUrl = 'http://127.0.0.1:5000/question';
                this.chatData.push({type : 'question', text : this.question});
                fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 'question' : this.question }),
                })
                .then(response => response.json())
                .then(data => {this.chatData.push({type : 'answer' , text : data.answer})})
                .catch(error => {
                    console.log(error);
                })
                this.question = '';
            }
        }
    }

</script>
